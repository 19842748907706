import { useState } from "react";
import { GrSearch } from "react-icons/gr";
import { locationIds } from "../../../../core/enums";
import { capitalize } from "../../../../utils/capitalize";
import Loader from "../../../Atoms/Loader";
import { formatDateRange } from "../../MultiCardModal/components/DateRangeSearch";
import { formatGuests } from "../../MultiCardModal/components/GuestSearch";
import { formatTags } from "../../MultiCardModal/components/TagSearch";
import s from "../SearchBar.module.scss";
import SearchBarElement from "../components/SearchBarElement";
import { SearchBarProps } from "../types";
import SearchModal from "./SearchModal";

export default function SmallSearchBar({ loading, ...props }: SearchBarProps) {
  const [openModal, setOpenModal] = useState(false);

  const value = [
    formatDateRange(props.from, props.until),
    formatGuests(props.numGuests),
    formatTags({ tags: props.tags, tagOptions: props.tagOptions }),
  ].join(" · ");

  const locationLabel = capitalize(
    Object.entries(locationIds).find(
      ([_, id]) => props.locationId === id
    )?.[0] ?? ""
  );

  return (
    <div className={s.SearchBar}>
      <SearchBarElement
        label={locationLabel}
        value={value}
        variant="mobile"
        startAdornment={
          <div
            style={{ position: "relative", left: -8, top: 4, marginRight: 8 }}
          >
            <GrSearch size={24} />
          </div>
        }
        endAdornment={
          loading ? (
            <div style={{ position: "absolute", right: 4 }}>
              <Loader color="#f33f3a" />
            </div>
          ) : (
            <></>
          )
        }
        onClick={() => setOpenModal(true)}
      />
      <SearchModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        {...props}
      />
    </div>
  );
}
