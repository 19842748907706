import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { MdStarBorder as MenuStarIcon } from "react-icons/md";
import { RxCaretDown } from "react-icons/rx";
import ReactModal from "react-modal";
import { locationIds } from "../../../core/enums";
import { EventTracker } from "../../Atoms/Analytics";
import CurrencyPicker from "../../Atoms/CurrencyPicker";
import SelectButton from "../../Atoms/SelectButton";
import SearchBar from "../../Cards/SearchBar";
import CheckoutHeader from "../CheckoutHeader";
import Logo from "../Logo";
import DestinationPicker from "./DestinationPicker";
import styles from "./Header.module.css";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showCurrency, setShowCurrency] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === "/bookings/[id]") {
      setShowCurrency(false);
    } else {
      setShowCurrency(true);
    }
  }, [router]);

  const isCheckout =
    router.pathname === "/bookings/[id]" ||
    router.pathname === "/checkout/[id]" ||
    router.pathname === "/payments/[id]"
      ? true
      : false;

  const hideHeader =
    isCheckout ||
    router.pathname === "/guide/[guideId]/sign-contracts" ||
    router.pathname === "/guide/sign-contracts-success";

  return (
    <>
      {isCheckout ? <CheckoutHeader /> : null}
      <div
        className={styles.AppHeader}
        style={hideHeader ? { display: "none" } : null}
      >
        <div className="AppLayout">
          <div className={styles.AppHeaderInner}>
            <Link
              href="/?ref=logo"
              title="MagicalTrip Homepage"
              className={styles.AppHeaderLogo}
            >
              <Logo height={40} color="#fff" />
            </Link>
            <CityPicker />
            <EventTracker
              onClick={{
                category: "header",
                action: "click",
                label: "destination-picker",
              }}
            >
              <SelectButton pressed={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <p>All cities</p>
                <RxCaretDown size={24} style={{ margin: "0px -6px" }} />
              </SelectButton>
            </EventTracker>
            <ReactModal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              ariaHideApp={false}
            >
              <DestinationPicker
                onCityPick={(locationId) => {
                  router.push(`/tours-japan/${locationId}`);
                  setIsOpen(false);
                }}
              />
            </ReactModal>
            <div className={styles.ButtonGroup}>
              <SearchBar variant="tiny" />

              <nav
                className={styles.AppHeaderNav}
                style={{ alignItems: "flex-end" }}
              >
                {showCurrency ? (
                  <ul>
                    <li className={styles.CurrencyDropDown}>
                      <CurrencyPicker />
                    </li>
                  </ul>
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <SiteWideMessage /> */}
    </>
  );
}

function CityPicker() {
  return (
    <nav
      title="Search by city navigation"
      className={styles.AppHeaderCityPicker}
    >
      <ul>
        <li>
          <Link
            href={`/tours-japan/${locationIds.TOKYO}?ref=nav`}
            className={styles.AppHeaderButton}
          >
            <MenuStarIcon size={18} />
            <span> Tokyo</span>
          </Link>
        </li>
        <li>
          <Link
            href={`/tours-japan/${locationIds.OSAKA}?ref=nav`}
            className={styles.AppHeaderButton}
          >
            <MenuStarIcon size={18} />
            <span> Osaka</span>
          </Link>
        </li>
        <li>
          <Link
            href={`/tours-japan/${locationIds.KYOTO}?ref=nav`}
            className={styles.AppHeaderButton}
          >
            <MenuStarIcon size={18} />
            <span> Kyoto</span>
          </Link>
        </li>
        <li>
          <Link
            href={`/tours-japan/${locationIds.HIROSHIMA}?ref=nav`}
            className={styles.AppHeaderButton}
          >
            <MenuStarIcon size={18} />
            <span> Hiroshima</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
