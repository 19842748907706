import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { createApolloAnalyticsLink } from "../utils/analytics";
import * as analytics2 from "../utils/analytics2";
import { configuration } from "./config";
const { createUploadLink } = require("apollo-upload-client");

const retryLink = new RetryLink({
  delay: {
    initial: 300, // Delay before the first retry (in milliseconds)
    max: 2000, // Maximum delay between retries
    jitter: true, // Apply random jitter to avoid retry storms
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      // Retry only if the error is a network error or specific status codes
      return !!error && error.networkError;
    },
  },
});

const isDev = () => process.env.ENVIRONMENT === "development";

const analyticsTracker = isDev()
  ? analytics2.defaultTracker
  : analytics2.tracker;

const analyticsLink = createApolloAnalyticsLink(analyticsTracker);

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

const uploadLink = createUploadLink({
  uri: configuration.API_URL,
  credentials: "same-origin",
});

function createApolloClient({ analyticsLink }) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([retryLink, analyticsLink, uploadLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient({ analyticsLink });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: any; revalidate?: number }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: { [x: string]: any }) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
