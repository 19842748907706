import classNames from "classnames";
import { useRouter } from "next/router";
import { locationIds } from "../../../core/enums";
import CityPoster from "../CityPoster";
import styles from "./CityNavigation.module.css";

const nav = [
  {
    id: locationIds.TOKYO,
    name: "Tokyo",
    poster: `tokyo`,
  },
  {
    id: locationIds.OSAKA,
    name: "Osaka",
    poster: `osaka`,
  },
  {
    id: locationIds.KYOTO,
    name: "Kyoto",
    poster: `kyoto`,
  },
  {
    id: locationIds.HIROSHIMA,
    name: "Hiroshima",
    poster: `hiroshima`,
  },
  {
    id: locationIds.NARA,
    name: "Nara",
    poster: `nara`,
  },
  {
    id: locationIds.HOKKAIDO,
    name: "Sapporo",
    poster: `sapporo`,
  },
  {
    id: locationIds.HYOGO,
    name: "Hyogo",
    poster: `hyogo`,
  },
  {
    id: locationIds.OKINAWA,
    name: "Okinawa",
    poster: `okinawa`,
  },
  {
    id: locationIds.ISHIKAWA,
    name: "Ishikawa",
    poster: `ishikawa`,
  },
].map((city) => ({ ...city, url: `/tours-japan/${city.id}` }));

type City = (typeof nav)[number];
type PartialCity = Pick<City, "id" | "name"> &
  Partial<Omit<City, "id" | "name">>;

type CityTilesProps = {
  includeAnywhere?: boolean;
  onClick?: (city: PartialCity) => void;
  highlightId?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">;

export function CityTiles({ includeAnywhere, ...props }: CityTilesProps) {
  return (
    <div className={styles.CityTabContainer}>
      {nav.map((city) => (
        <CityTile key={city.id} city={city} {...props} />
      ))}
      {includeAnywhere && (
        <CityTile
          city={{
            id: undefined,
            name: "Anywhere",
          }}
          {...props}
        />
      )}
    </div>
  );
}

type CityTileProps = {
  city: PartialCity;
} & CityTilesProps;
function CityTile({ city, highlightId, onClick, ...divProps }: CityTileProps) {
  return (
    <div
      key={city.id}
      {...divProps}
      className={classNames(styles.cityTab, {
        [styles.highlighted]: city.id === highlightId,
      })}
      onClick={() => onClick(city)}
    >
      {city.poster && (
        <div key={city.name} className={styles.cityTabImage}>
          <CityPoster name={city.poster} width={75} />
        </div>
      )}
      <span
        style={{
          ...(!city.poster && { marginLeft: 75 }),
        }}
      >
        {city.name}
      </span>
    </div>
  );
}

export default function CityNavigation() {
  const router = useRouter();

  return <CityTiles onClick={(city) => router.push(city.url)} />;
}
