import {
  addDays,
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  setDate,
  startOfDay,
  startOfMonth,
} from "date-fns";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import s from "./DateRangePicker.module.scss";
import useSwipe from "./useSwipe";

export const CALENDER_WIDTH = 336;
export const CAROUSEL_GAP = 48;
export const CAROUSEL_FADE_EFFECT_WIDTH = 16;
export const CAROUSEL_DISPLACEMENT =
  CALENDER_WIDTH + CAROUSEL_GAP - CAROUSEL_FADE_EFFECT_WIDTH;

export type DateRangePickerProps = {
  from: Date | null;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
  numCalenders?: number;
} & Pick<CalenderDateProps, "maxRange">;

export default function DateRangePicker(props: DateRangePickerProps) {
  return (
    <div className={s.DateRangerPicker}>
      <MultiCalender {...props} numCalenders={props.numCalenders ?? 1} />
    </div>
  );
}

const weekdays = Array.from({ length: 7 }).map((_, day) =>
  new Date(0, 0, day)
    .toLocaleDateString("en-US", { weekday: "long" })
    .slice(0, 3)
);

type ChangeMonthButtonProps = {
  originalDate: Date;
  deltaMonths: number;
  setDate: Dispatch<SetStateAction<Date>>;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const changeDate = (
  deltaMonths: number,
  originalDate: Date,
  setDate: Dispatch<SetStateAction<Date>>
) =>
  setDate((date) => {
    const newDate = addMonths(date, deltaMonths);
    const monthOffset = differenceInMonths(newDate, originalDate);
    displaceCarousel(monthOffset + 1);

    return newDate;
  });

function ChangeMonthButton({
  originalDate,
  deltaMonths,
  setDate,
  children,
  ...props
}: PropsWithChildren<ChangeMonthButtonProps>) {
  const onClick = () => {
    fitCarouselHeightToChildren();

    changeDate(deltaMonths, originalDate, setDate);
  };
  return (
    <button className={s.ChangeMonthButton} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

type MultiCalenderProps = Omit<BasicCalenderProps, "displayDate"> & {
  numCalenders?: number;
} & Pick<CalenderDateProps, "maxRange">;

function MultiCalender({ numCalenders, ...props }: MultiCalenderProps) {
  const originalDate = useMemo(() => props.from, [props.from]);
  const [displayDate, setDisplayDate] = useState(props.from);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => changeDate(1, originalDate, setDisplayDate),
    onSwipedRight: () => changeDate(-1, originalDate, setDisplayDate),
  });

  useEffect(() => {
    // set carousel width according to number of calenders
    const carouselContainer = document.getElementById(
      "CalenderCarouselContainer"
    );
    carouselContainer.style.width = `${
      CALENDER_WIDTH * numCalenders +
      CAROUSEL_GAP * (numCalenders - 1) +
      2 * CAROUSEL_FADE_EFFECT_WIDTH
    }px`;

    fitCarouselHeightToChildren();
    displaceCarousel();
  }, [numCalenders]);

  const untilOrFutureHover = props.until
    ? props.until
    : isAfter(hoveredDate, props.from)
    ? hoveredDate
    : null;

  return (
    <div className={s.DualCalender}>
      <ChangeMonthButton
        deltaMonths={-1}
        originalDate={originalDate}
        setDate={setDisplayDate}
      >
        &lt;
      </ChangeMonthButton>
      <div
        {...swipeHandlers}
        id="CalenderCarouselContainer"
        className={s.CalenderCarouselContainer}
      >
        <div id="CalenderCarousel" className={s.CalenderCarousel}>
          {/* add numCalenders + 2 for padding on both ends of the carousel for smooth transitions */}
          {Array.from({ length: numCalenders + 2 })
            .map((_, i) => i - 1)
            .map((deltaMonths) => (
              <Calender
                key={deltaMonths}
                displayDate={addMonths(displayDate, deltaMonths)}
                untilOrFutureHover={untilOrFutureHover}
                setHovered={setHoveredDate}
                {...props}
              />
            ))}
        </div>
      </div>
      <ChangeMonthButton
        deltaMonths={1}
        originalDate={originalDate}
        setDate={setDisplayDate}
      >
        &gt;
      </ChangeMonthButton>
    </div>
  );
}

type BasicCalenderProps = {
  displayDate?: Date;
  from: Date | null;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
};

const classNames = (classNames: { [name: string]: boolean }) =>
  Object.entries(classNames)
    .filter(([_, condition]) => condition)
    .map(([name, _]) => name)
    .join(" ");

type HoveredProps = {
  untilOrFutureHover: Date | null;
  setHovered: Dispatch<SetStateAction<Date | null>>;
};
type CalenderProps = BasicCalenderProps &
  HoveredProps & {
    maxRange?: number;
  };

function Calender({
  displayDate = new Date(),
  from,
  setFrom,
  until,
  setUntil,
  untilOrFutureHover,
  setHovered,
  maxRange = 30,
}: CalenderProps) {
  const month = getMonth(displayDate);
  const year = getYear(displayDate);
  const weekdayOfFirstDateInMonth = getDay(setDate(displayDate, 1));

  return (
    <div className={s.CalenderContainer}>
      <div
        style={{ textAlign: "center", fontWeight: 500, marginBottom: "16px" }}
      >
        {format(displayDate, "MMMM Y")}
      </div>
      <ol className={s.Calender}>
        {weekdays.map((day) => (
          <div key={day} className={s.Weekday}>
            {day}
          </div>
        ))}
        {Array.from({ length: getDaysInMonth(displayDate) }).map((_, date) => {
          const fullDate = new Date(year, month, date + 1);

          return (
            <CalenderDate
              key={date}
              fullDate={fullDate}
              from={from}
              setFrom={setFrom}
              until={until}
              setUntil={setUntil}
              untilOrFutureHover={untilOrFutureHover}
              setHovered={setHovered}
              maxRange={maxRange}
              noPastDates
            />
          );
        })}
      </ol>
    </div>
  );
}

type CalenderDateProps = CalenderProps & {
  fullDate: Date;
  noPastDates?: boolean;
};

const isStartOfMonth = (date: Date) => date.getDate() === 1;
const isEndOfMonth = (date: Date) =>
  endOfMonth(date).getDate() === date.getDate();

function CalenderDate({
  fullDate,
  from,
  setFrom,
  until,
  setUntil,
  untilOrFutureHover,
  setHovered,
  noPastDates,
  maxRange,
}: CalenderDateProps) {
  const date = getDate(fullDate);
  const weekdayOfFirstDateInMonth = getDay(startOfMonth(fullDate));
  const today = startOfDay(new Date());
  const key = format(fullDate, "yyyy-MM-dd");

  const isSelectableDate =
    // max date in the future is 15 months
    isBefore(fullDate, addMonths(today, 15)) &&
    // if we dont allow past dates, the date most be today or later
    (noPastDates
      ? isAfter(fullDate, today) || isEqual(fullDate, today)
      : true) &&
    (maxRange && from && !until
      ? isBefore(fullDate, addDays(from, maxRange))
      : true);

  return (
    <li
      key={key}
      onMouseEnter={() => isSelectableDate && setHovered(fullDate)}
      onMouseLeave={() => isSelectableDate && setHovered(null)}
      className={classNames({
        [s.DateContainer]: true,
        [s.Selectable]: isSelectableDate,
        [s.BetweenSelected]:
          !isEqual(from, until) &&
          ((isEqual(from, fullDate) &&
            untilOrFutureHover &&
            !isStartOfMonth(from)) ||
            (isEqual(untilOrFutureHover, fullDate) &&
              !isEndOfMonth(untilOrFutureHover)) ||
            (isBefore(from, fullDate) &&
              (until
                ? isAfter(until, fullDate)
                : isAfter(untilOrFutureHover, fullDate)))),
        [s.FirstSelected]: isEqual(from, fullDate),
        [s.LastSelected]: until
          ? isEqual(until, fullDate)
          : untilOrFutureHover
          ? isEqual(untilOrFutureHover, fullDate)
          : isEqual(from, fullDate),
      })}
      onClick={() => {
        if (!isSelectableDate) return;
        if (until) {
          setFrom(fullDate);
          setUntil(null);
        } else {
          if (isBefore(from, fullDate) || isEqual(from, fullDate)) {
            setUntil(fullDate);
          } else {
            setFrom(fullDate);
          }
        }
      }}
      style={{
        gridColumnStart: date === 1 && weekdayOfFirstDateInMonth + 1,
      }}
    >
      <div
        className={classNames({
          [s.Date]: true,
          [s.Selectable]: isSelectableDate,
          [s.Selected]:
            isEqual(fullDate, from) ||
            (until
              ? isEqual(fullDate, until)
              : isEqual(fullDate, untilOrFutureHover)),
        })}
      >
        {date}
      </div>
    </li>
  );
}

const displaceCarousel = (monthOffset: number = 1) => {
  const carousel = document.getElementById("CalenderCarousel");
  carousel.style.left = `${-1 * CAROUSEL_DISPLACEMENT * (monthOffset + 1)}px`;
  carousel.style.transform = `translate(${
    CAROUSEL_DISPLACEMENT * monthOffset
  }px, 0)`;
};

const fitCarouselHeightToChildren = () => {
  const carousel = document.getElementById("CalenderCarousel");

  const children = carousel.children;

  // Adjust height of children
  // so that children on the outside of the carousel do not influence the carousel height
  // @ts-ignore
  [...children]
    .slice(1, children.length)
    // @ts-ignore
    .forEach((child) => delete child.style.maxHeight);

  // @ts-ignore
  [children[0], children[children.length - 1]].forEach(
    // @ts-ignore
    (child) => (child.style.maxHeight = 0)
  );
};
