import { useCallback, useEffect, useRef, useState } from "react";
import SearchBarElement from "../../../components/SearchBarElement";

export type OpenDialogProps = {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  openNext: () => void;
};
type SearchCardProps = OpenDialogProps & {
  value: string;
  label: string;
};

export default function SearchCard({
  label,
  value,
  children,
  isOpen,
  openDialog,
  closeDialog,
}: React.PropsWithChildren<SearchCardProps>) {
  const buttonRef = useRef(null);
  const contentRef = useRef(null);
  const [buttonBoundingRect, setButtonBoundingRect] = useState<DOMRect | null>(
    null
  );

  useEffect(() => {
    // keep track of the button position so the dialog is positioned accordingly
    const button = buttonRef.current;

    if (!button) return;

    const updateBoundingClientRect = () => {
      const rect = button.getBoundingClientRect();

      setButtonBoundingRect(rect);
    };

    const resizeObserver = new ResizeObserver(updateBoundingClientRect);
    resizeObserver.observe(button);

    const mutationObserver = new MutationObserver(updateBoundingClientRect);
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const handleScroll = () => updateBoundingClientRect();
    window.addEventListener("scroll", handleScroll, { passive: true });

    updateBoundingClientRect();

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleEvent = useCallback(
    // close the dialog if clicking outside the dialog
    ({ target }: MouseEvent) => {
      if (
        isOpen &&
        !(
          buttonRef.current?.contains(target as Node) ||
          contentRef.current?.contains(target as Node)
        )
      ) {
        closeDialog();
        document.removeEventListener("click", handleEvent);
      }
    },
    [isOpen, closeDialog]
  );

  useEffect(() => {
    if (isOpen)
      // set a tiny timeout on adding the event handler
      // such that opening the dialog from a different click doesn't immediately shut it again
      setTimeout(() => document.addEventListener("click", handleEvent), 1);

    return () => document.removeEventListener("click", handleEvent);
  }, [isOpen, handleEvent]);

  return (
    <SearchBarElement
      selected={isOpen}
      label={label}
      value={value}
      onClick={() => (isOpen ? closeDialog() : openDialog())}
      buttonRef={buttonRef}
      buttonBoundingRect={buttonBoundingRect}
    >
      <div ref={contentRef}>{isOpen && children}</div>
    </SearchBarElement>
  );
}
