import { Dispatch, SetStateAction } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { addDuration } from "../../../../utils/calendarUtils";
import { useSession } from "../../../Atoms/AppSession";
import Button from "../../../Atoms/Button";
import { SearchCardProps } from "./SearchCard";

type SearchTodayTomorrowSearchCardProps = Omit<SearchCardProps, "label"> & {
  setFrom: Dispatch<SetStateAction<Date | null>>;
  setUntil: Dispatch<SetStateAction<Date | null>>;
  searchAndClose: () => Promise<void>;
};

export default function SearchTodayTomorrowSearchCard({
  setFrom,
  setUntil,
  searchAndClose,
}: SearchTodayTomorrowSearchCardProps) {
  const session = useSession();
  const { countryCode } = session;
  const today = new Date();
  const tomorrow = addDuration(today, { days: 1 });
  const searchOnlyTodayTomorrow = () => {
    setFrom(today);
    setUntil(tomorrow);
    setTimeout(() => {
      searchAndClose();
    }, 500);
  };
  return (
    <>
      {countryCode === "Asia/Tokyo" && (
        <Button
          style={{
            background: "#28b493",
            borderRadius: 8,
            padding: 16,
            height: "auto",
            zIndex: 0,
          }}
          small
          onClick={searchOnlyTodayTomorrow}
        >
          Find Tours Available Today or Tomorrow{" "}
          <FaChevronCircleRight
            style={{ position: "relative", top: 3, left: 5 }}
          />
        </Button>
      )}
    </>
  );
}
