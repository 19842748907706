import { Dispatch, SetStateAction } from "react";
import { CityTiles } from "../../CityNavigation";
import { formatLocation } from "./LocationSearch";
import SearchCard, { SearchCardProps } from "./SearchCard";

type LocationSearchCardProps = Omit<SearchCardProps, "label"> & {
  locationId: string;
  setLocationId: Dispatch<SetStateAction<string>>;
};

export default function LocationSearchCard({
  locationId,
  setLocationId,
  ...searchCardProps
}: LocationSearchCardProps) {
  return (
    <SearchCard {...searchCardProps} label={formatLocation(locationId)}>
      <CityTiles
        includeAnywhere
        highlightId={locationId}
        onClick={(city) => {
          setLocationId(city.id);
          searchCardProps.expandNextCard();
        }}
      />
    </SearchCard>
  );
}
