import { useEffect, useRef, useState } from "react";
import DateRangePicker, {
  CAROUSEL_DISPLACEMENT,
  DateRangePickerProps,
} from "../DateRangePicker";

export type ScalingDateRangePickerProps = Omit<
  DateRangePickerProps,
  "numCalenders"
> &
  Pick<DateRangePickerProps, "maxRange"> &
  (
    | {
        numCalenders?: {
          min?: number;
          max?: number;
        };
      }
    | { numCalenders?: number }
  );

const clamp = (value: number, min: number, max: number) =>
  Math.min(max, Math.max(min, value));

export default function ScalingDateRangePicker(
  props: ScalingDateRangePickerProps
) {
  const [cardWidth, setCardWidth] = useState(1);
  const [datePickerHeight, setDatePickerHeight] = useState(357);
  const [minCalenders, maxCalenders] =
    typeof props.numCalenders === "number"
      ? [props.numCalenders, props.numCalenders]
      : [
          props.numCalenders.min ?? 1,
          props.numCalenders.max ?? (props.numCalenders.min ?? 1) + 1,
        ];
  const [numCalenders, setNumCalenders] = useState(minCalenders);

  const defaultScale = 1 as const;
  const maxScaleDeviation = 0.3 as const;
  const minScale = defaultScale - maxScaleDeviation;
  const maxScale = defaultScale + maxScaleDeviation;
  const [datePickerScale, setDatePickerScale] = useState<number>(defaultScale);

  const cardRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (cardRef.current && datePickerRef.current) {
        const dp = CAROUSEL_DISPLACEMENT;
        setCardWidth(cardRef.current.offsetWidth);
        setNumCalenders(
          clamp(
            Math.floor(cardWidth / (dp * minScale)),
            minCalenders,
            maxCalenders
          )
        );
        setDatePickerScale(
          clamp(cardWidth / (numCalenders * dp), minScale, maxScale)
        );
      }
    };

    // Call the function to update width when the component mounts
    updateWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [
    cardWidth,
    numCalenders,
    datePickerScale,
    maxCalenders,
    maxScale,
    minCalenders,
    minScale,
  ]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const datePicker = entries.find(
        ({ target }) => target === datePickerRef.current
      );
      setDatePickerHeight(datePicker.contentRect.height * datePickerScale);
    });

    const datePicker = datePickerRef.current;

    if (datePicker) resizeObserver.observe(datePicker);

    return () => {
      if (datePicker) resizeObserver.unobserve(datePicker);
      resizeObserver.disconnect();
    };
  }, [datePickerScale]);

  return (
    <div
      style={{
        height: datePickerHeight,
        transition: "height 200ms ease",
      }}
    >
      <div
        ref={cardRef}
        style={{
          display: "flex",
          justifyContent: "center",
          transformOrigin: "top",
          transform: `scale(${datePickerScale * 100}%)`,
        }}
      >
        <div ref={datePickerRef}>
          <DateRangePicker {...props} numCalenders={numCalenders} />
        </div>
      </div>
    </div>
  );
}
