import classNames from "classnames";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import s from "../SearchBar.module.scss";

type SearchBarElementProps = {
  selected?: boolean;
  label: string;
  value: string;
  variant?: "default" | "mobile";
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  buttonRef?: MutableRefObject<any>;
  buttonBoundingRect?: DOMRect;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function SearchBarElement({
  variant = "default",
  selected,
  label,
  value,
  startAdornment,
  endAdornment,
  children,
  buttonRef,
  buttonBoundingRect,
  ...props
}: PropsWithChildren<SearchBarElementProps>) {
  const [dialogEl, setDialogEl] = useState(null);

  const dialogWidth = useMemo(
    () => dialogEl?.getBoundingClientRect().width ?? 0,
    [dialogEl]
  );

  const dialogDisplacement = useMemo(
    () => ((buttonBoundingRect?.width ?? 0) - dialogWidth) / 2,
    [buttonBoundingRect, dialogWidth]
  );

  const canRenderPortal = typeof document !== "undefined";

  return (
    <div className={s.SearchBarElementContainer}>
      <div
        className={classNames(s.SearchBarElement, {
          [s.Mobile]: variant === "mobile",
          [s.Selected]: selected,
        })}
        ref={buttonRef}
        {...props}
      >
        {startAdornment && startAdornment}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "90%",
          }}
        >
          <label className={classNames({ [s.Large]: variant === "mobile" })}>
            {label}
          </label>
          <span
            className={classNames({ [s.Large]: variant !== "mobile" })}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </span>
        </div>
        {endAdornment && endAdornment}
      </div>
      {canRenderPortal &&
        selected &&
        createPortal(
          <dialog
            key={label}
            ref={setDialogEl}
            style={{
              top:
                (buttonBoundingRect?.top ?? 0) +
                (buttonBoundingRect?.height ?? 0) +
                gapWidthBetweenButtonAndDialog +
                window.scrollY,
              left: (buttonBoundingRect?.left ?? 0) + window.scrollX,
              transform: `translateX(${dialogDisplacement}px)`,
            }}
            className={s.SearchBarDialog}
          >
            {children}
          </dialog>,
          document.body
        )}
    </div>
  );
}

const gapWidthBetweenButtonAndDialog = 8 as const;
