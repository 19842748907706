import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { locationIds } from "../../../core/enums";
import { Location, LocationTranslation } from "../../../graphql/operations";
import Loader from "../../Atoms/Loader";
import { Text } from "../../Atoms/Typography";
import styles from "./Header.module.css";

const keywordMap: { [key: string]: string } = {
  [locationIds.TOKYO]: "Shinjuku, Shibuya, Asakusa",
  [locationIds.OSAKA]: "Dotonbori, Namba, Kuromon",
  [locationIds.KYOTO]: "Fushimi Inari, Arashiyama, Pontocho",
  [locationIds.HIROSHIMA]: "Heiwa, Miyajima",
  [locationIds.NARA]: "Deer park, Tōdaiji-ji",
  [locationIds.HOKKAIDO]: "Hokkaido, Susukino",
  [locationIds.HYOGO]: "Himeji castle, Hyogo",
  [locationIds.KENROKUEN]: "Kenrokuen Garden, Samurai District",
  [locationIds.THAILAND]: "Thai Food, Chinatown, Tuk Tuk ride",
  [locationIds.YOKOHAMA]: "Yokohama, Kamakura, Odawara",
};

type DestinationPickerProps = {
  onCityPick: (locationId: Location["id"] | undefined) => void;
  selectedId?: string;
  includeAnywhere?: boolean;
};

export default function DestinationPicker({
  onCityPick,
  selectedId,
  includeAnywhere,
}: DestinationPickerProps) {
  const { loading, error, data } =
    useQuery<GetLocationsResponse>(GET_LOCATIONS);

  if (loading) {
    return <Loader />;
  }

  if (error || !data || !data.locations) {
    return <>Could not load cities.</>;
  }

  return (
    <ul className={styles.DestinationPicker}>
      {(data.locations.records || []).map(
        (location) =>
          location && (
            <DestinationListItem
              key={location.id}
              id={location.id}
              name={location.translation.name}
              onCityPick={onCityPick}
              selectedId={selectedId}
            />
          )
      )}
      {includeAnywhere && (
        <DestinationListItem
          key="anywhere"
          id={undefined}
          name="Anywhere"
          onCityPick={onCityPick}
          selectedId={selectedId}
        />
      )}
    </ul>
  );
}

type DestinationListItemsProps = {
  id: Location["id"] | undefined;
  name: Location["translation"]["name"];
} & Pick<DestinationPickerProps, "onCityPick" | "selectedId">;

function DestinationListItem({
  id,
  name,
  onCityPick,
  selectedId,
}: DestinationListItemsProps) {
  return (
    <li key={id} onClick={() => onCityPick(id)}>
      <div
        className={classNames(styles.DestinationPickerItem, {
          [styles.Selected]: id === selectedId,
        })}
      >
        <b>{name}</b>
        <Text size="smaller" muted>
          {keywordMap[id]}
        </Text>
      </div>
    </li>
  );
}

type GetLocationsResponse = {
  locations: {
    records: (Pick<Location, "id"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

const GET_LOCATIONS = gql`
  query DestinationPickerQuery {
    locations(
      input: {
        hasProducts: true
        locationTypes: [PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      records {
        id
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
