import { PropsWithChildren } from "react";
import s from "../SearchModal.module.scss";

export type SearchCardProps = {
  shortTitle: string;
  title: string;
  label: string | React.ReactNode;
  state?: "EXPANDED" | "COLLAPSED";
  expandCard?: () => void;
  expandNextCard?: () => void;
};

export default function SearchCard({
  state,
  shortTitle,
  title,
  expandCard,
  label,
  children,
  ...divProps
}: PropsWithChildren<SearchCardProps> & React.HTMLAttributes<HTMLDivElement>) {
  const isCollapsed = state === "COLLAPSED";
  return (
    <div
      onClick={isCollapsed ? expandCard : undefined}
      className={s.Card}
      {...divProps}
      style={divProps.style}
    >
      {isCollapsed ? (
        <div className={s.Collapsed}>
          <h4>{shortTitle}</h4>
          <h4 className={s.Label}>{label}</h4>
        </div>
      ) : children ? (
        <>
          <h1 className={s.ExpandedCardTitle}>{title}</h1>
          <div>{children}</div>
        </>
      ) : (
        <div>expanded</div>
      )}
    </div>
  );
}
