import { DetailedHTMLProps, HTMLAttributes } from "react";
import { GrSearch } from "react-icons/gr";
import Button from "../../../Atoms/Button";
import Loader from "../../../Atoms/Loader";
import s from "../SearchBar.module.scss";

type SearchButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { loading?: boolean };

export default function SearchButton({ loading, ...props }: SearchButtonProps) {
  return loading ? (
    <div style={{ position: "absolute", top: 4, right: 4 }}>
      <Loader color="#f33f3a" />
    </div>
  ) : (
    <span {...props} className={s.SearchButton}>
      <Button>
        <GrSearch />
      </Button>
    </span>
  );
}
